import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { COLORS } from "../styles/constants"
import StarsBackground from "../components/stars-bg"

const EmailConfirmationPage = () => (
  <>
    <StarsBackground />
    <Layout>
      <SEO title="Waitlist Email Confirmation"/>
      <h1 style={{ marginTop: "2rem" }}>Thank you for confirming your email for our waitlist</h1>
      <p><strong>Our product goals:</strong></p>
      <ul className="hero-bullets">
        <li><span>One-time setup cost, <strong>avoid monthly hosting costs</strong></span></li>
        <li><span>A solution you <strong>100% control</strong> - a generated website you can fully customize (React, Gatsby, Netlify)</span></li>
        <li><span>Step-by-step guides to help you <strong>validate your idea</strong></span></li>
        <li><span>Super-fast, <strong>SEO-optimized</strong> landing pages</span></li>
      </ul>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#e5e4f5" width="24" height="24" viewBox="0 0 24 24"><path d="M21.856 10.303c.086.554.144 1.118.144 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.347 0 4.518.741 6.304 1.993l-1.422 1.457c-1.408-.913-3.082-1.45-4.882-1.45-4.962 0-9 4.038-9 9s4.038 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.866-1.902zm-.952-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z"/></svg>
        <strong className="check-label" style={{ margin: "0 16px 0 6px", lineHeight: "24px" }}>Product Waitlist</strong>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#e5e4f5" width="24" height="24" viewBox="0 0 24 24"><path d="M21.856 10.303c.086.554.144 1.118.144 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.347 0 4.518.741 6.304 1.993l-1.422 1.457c-1.408-.913-3.082-1.45-4.882-1.45-4.962 0-9 4.038-9 9s4.038 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.866-1.902zm-.952-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z"/></svg>
        <strong className="check-label" style={{ margin: "0 16px 0 6px", lineHeight: "24px" }}>Email List Sign-up</strong>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#e5e4f5" width="24" height="24" viewBox="0 0 24 24"><path d="M21.856 10.303c.086.554.144 1.118.144 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.347 0 4.518.741 6.304 1.993l-1.422 1.457c-1.408-.913-3.082-1.45-4.882-1.45-4.962 0-9 4.038-9 9s4.038 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.866-1.902zm-.952-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z"/></svg>
        <strong className="check-label" style={{ margin: "0 16px 0 6px", lineHeight: "24px" }}>Conversion Tracking</strong>
      </div>
      <br/>
      <hr style={{borderTop: "1px solid white"}}/>
      <p><strong>Have suggestions or feedback?</strong></p>
      <p>Reach out to <span style={{ color: COLORS.accentGradient}}><strong>justin@warplanding.com</strong></span></p>
    </Layout>
  </>
)

export default EmailConfirmationPage